import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import instance from "../services";
import NotFoundPage from "./404";
import Header from "../components/Header";
import VerifyOtp from "../components/verify-otp";
import { useNavigate, useLocation } from "react-router-dom"
import Footer from "../components/Footer";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Color from "color";
import '../services/index.css'
import { MdOutlinePhonelinkLock } from "react-icons/md";
import PreLoader from "../components/PreLoader";
import ContactDetailsHeader from "../components/ContactDetailsHeader";
import NDRModal from "../components/NDRModal";


function BaseTemplate({ Details, templateId, detailsLoading }) {

    const [OTP, setOTP] = useState('')
    const [status, setStatus] = useState('')
    const [statusLoading, setStatusLoading] = useState(false)
    const [showTooltip
        , setShowTooltip
    ] = useState(false)
    const [showTrackModal, setShowTrackModal] = useState(false);
    const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
    const [showNDRModal, setShowNDRModal] = useState(false);


    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams()
    const loc = location.pathname.split('/')

    useEffect(() => {

        loc[2] === 'order_id' && localStorage.setItem('type', 'customer_referenc')
        loc[2] === 'awb' && localStorage.setItem('type', 'awb')
        loc[3] === '' && navigate('/track')


        getStatus();


    }, []);

    var themeColor = Details?.theme_color && Details?.theme_color[0] == '#' && Details?.theme_color != "#FFFFFF" && Color(Details?.theme_color).luminosity() < 0.7 ? Details?.theme_color : '#3077bf'; // #10b981

    var textColorBasedOnThemeColor = Color(themeColor).isDark() ? '#FFFFFF' : '#000000';


    // get track status
    const getStatus = async () => {
        setStatusLoading(true);
        try {
            const res = await instance.get(`/api/getStatus/${id}`)
            // console.log("res", res.data.data.msg)

            setStatus(res.data.data.msg)
            setTimeout(() => {

                setStatusLoading(false);
            }, 500);

        }
        catch (e) {
            console.log(e)
            setStatusLoading(false);
            navigate('/track')
        }
    }


    // function to verify user first before rating and review
    const verifyFirst = () => {
        setShowVerifyOtpModal(true);
    }

    //verify OTP
    const otpVerification = (data) => {
        // console.log(data)
        setOTP(data)

    }

    // Modal Code to ask for sending OTP i.e. before the sending modal
    const verifyOTPModal = () => {
        return (
            <>
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none h-full w-full bg-black bg-opacity-75">
                    <div className="relative w-auto my-6 mx-auto max-w-sm">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="p-4 font-semibold">You need to verify your phone number to rate and  review the services! </div>
                            <div className="px-4 text-sm text-red-500 pb-4">Note: Please note that by clicking on <b>"Verify"</b>, <b>OTP</b> will be sent to your phone.</div>

                            <div className="flex items-center justify-between  border-t border-solid border-blueGray-200 rounded-b px-5 py-3">
                                <button
                                    className="text-gray-500 hover:bg-gray-500 hover:text-white border-gray-500 border rounded-md
                                     font-semibold  px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={() => setShowVerifyOtpModal(false)}
                                >
                                    Close
                                </button>
                                <button
                                    className="text-white bg-blue-500 active:bg-blue-700 font-semibold  text-sm px-6 py-2 rounded shadow hover:shadow-md hover:bg-blue-800 outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={() => { setShowVerifyOtpModal(false); generateotp(); }}
                                >
                                    Verify
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }

    // generate OTP request
    //TODO:Check Here
    const generateotp = async () => {
        setShowTrackModal(true)
        try {
            const res = await instance.post("/api/gen_otp", { tracking_number: id, phone: status?.phone })
            // console.log(res.data)
            if (res.data.status) {
                setShowTrackModal(true)

            }
        }
        catch (error) {
            console.log(error)
        }
    }





    return (

        <div style={{ fontFamily: `${Details?.font?.name ? Details?.font?.name : "system-ui"}` }} >
            {(detailsLoading || statusLoading) && <PreLoader />}
            <VerifyOtp handleClose={() => setShowTrackModal(false)} showTrackModal={showTrackModal} verify={otpVerification} Status={status} id={id} phone={status?.phone} />

            <NDRModal handleClose={() => setShowNDRModal(false)} showNDRModal={showNDRModal} />

            {/* This is contact details header */}
            <ContactDetailsHeader Details={Details} />
            {/* header */}
            <Header Details={Details} />


            <div className="  xl:h-max xl:w-full bg-white   p-4 md:p-4 xl:p-0 ">
                {templateId == 1 ? <Template1 status={status} Details={Details} id={id} verifyFirst={verifyFirst} OTP={OTP} setShowNDRModal={setShowNDRModal} />
                    : <Template2 status={status} Details={Details} id={id} verifyFirst={verifyFirst} OTP={OTP} setShowNDRModal={setShowNDRModal} />}
                {/* footer */}
                <Footer Details={Details} />

            </div >

            {/* login floating button */}
            {!OTP &&
                <div className="mr-auto cursor-pointer fixed bottom-0 left-0">
                    {showTooltip && <div className="bg-black text-white m-4  w-48 p-2 rounded-md"> You don't have access to take any action on the tracking page. Click to verify yourself as a buyer.</div>
                    }
                    <div className="float-left md:pl-5 pl-1 pb-5 ">
                        <div onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)} className="text-white font-semibold hover:shadow-md text-sm p-4 rounded-full" style={{ backgroundColor: themeColor, color: textColorBasedOnThemeColor }} onClick={verifyFirst}> <MdOutlinePhonelinkLock size="1.5rem" /> </div>


                    </div>
                </div>
            }
            {showVerifyOtpModal && verifyOTPModal()}
        </div >

    );
}

export default BaseTemplate;
