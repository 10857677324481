import React from 'react'
import resize from "../images/resize.png";
function CustomerDetails({ status, setShowNDRModal }) {
    return (
        <div className=" w-full mx-auto  bg-white mb-5 px-5 py-5 rounded-md shadow-md border border-gray-200">

            <div className=" flex">
                <img src={resize} />
                <h1
                    className="pl-3 text-sm font-semibold"
                    style={{ color: "#1c1c1c" }}
                >
                    Your Shipment Details
                </h1>
                {/* <h1
                    className="cursor-pointer font-bold ml-auto text-customblue"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                        setShowNDRModal(true);
                    }}

                >
                    Request order return/re-attempt
                </h1> */}
            </div>

            <div className="border-b-2 my-3.5"></div>

            <div className="flex pt-1.5">
                <h1
                    className="font-semibold w-1/4 "
                    style={{ color: "#353637", fontSize: "14px" }}
                >
                    Order ID
                </h1>
                <h1
                    className="w-3/4 font-medium"
                    style={{ color: "#6d7073", fontSize: "14px" }}
                >
                    {status?.customer_referenc}
                </h1>
            </div>

            <div className="flex pt-1.5">
                <h1
                    className="font-semibold w-1/4"
                    style={{ color: "#353637", fontSize: "14px" }}
                >
                    Buyer
                </h1>
                <h1
                    className="w-3/4 font-medium"
                    style={{ color: "#6d7073", fontSize: "14px" }}
                >
                    {status.customer_name}
                </h1>
            </div>
            <div className="flex pt-1.5">
                <h1
                    className="font-semibold w-1/4"
                    style={{ color: "#353637", fontSize: "14px" }}
                >
                    Mobile
                </h1>
                <h1
                    className="w-3/4 font-medium"
                    style={{ color: "#6d7073", fontSize: "14px" }}
                >

                    {status.phone}
                </h1>
            </div>
            <div className="flex pt-1.5">
                <h1
                    className="font-semibold w-1/4"
                    style={{ color: "#353637", fontSize: "14px" }}
                >
                    Address
                </h1>
                <h1
                    className="w-3/4 font-medium"
                    style={{ color: "#6d7073", fontSize: "14px" }}
                >
                    {status?.custom_fields?.delivery_address}
                </h1>
            </div>
        </div>
    )
}

export default CustomerDetails