import React, { useEffect, useState } from 'react'
import instance from "../services";
import mixpanel from 'mixpanel-browser';

import frustated from "../images/frustated.png";
import happy from "../images/happy.png";
import better from "../images/better.png";
import arrowdown from "../images/arrow_down.png";
import { useFormValidation } from '../Authenticate/useFormValidation';

function RatingReactionForm({ status, Details, OTP, verifyFirst, id, singleBlock }) {

    const [courierrating, setcourierrating] = useState()
    const [productrating, setproductrating] = useState()
    const [review, setreview] = useState()


    // get review
    const get_review = async () => {
        try {
            const res = await instance.get(`/api/review/${id}`);
            // console.log(" get review :", res.data.meta.status);
            setreview(res.data.meta);
        } catch (e) {
            console.log(e);
        }
    };

    // submitting the rating and review
    const submit = async () => {

        if (OTP) {
            if (status?.tag === 'Delivered') {

                try {
                    const res = await instance.post("/api/review", {
                        ...data,
                        tracking_number: status?.tracking_number,
                        order_id: status?.customer_referenc,
                        courier_rating: courierrating,
                        product_rating: productrating,
                    });
                    setreview({ status: 'success' })
                    console.log(res.data);
                } catch (e) {
                    console.log(e);
                }
            } else {
                alert("You can give feedback only after order is 'DELIVERED'");
            }
        } else {
            verifyFirst();
        }
        // console.log( "data",data , 'tracking_number', status?.tracking_number, 'order_id', status?.order_id, 'courier_rating', courierrating, "product rating", productrating)
    };

    //form validation
    const { data, errors, handleChange, handleSubmit } = useFormValidation({
        initialValues: {
            comment: "",
        },
        validationSchema: {
            comment: {
                required: {
                    value: true,
                    message: "",
                },
            },
        },
        submit: submit,
    });


    useEffect(() => {
        get_review();
    }, [])


    const reactions = () => {
        return (<div className="bg-white mb-5 px-5 pt-4 pb-6 rounded-md mt-5 md:mt-0  border border-gray-200 shadow-md flex flex-col justify-between ">
            <h1 className="text-sm font-semibold" style={{ color: "#1c1c1c" }}>
                How you felt shopping with us?
            </h1>
            {OTP && review?.status === "success" ? (
                <div className="flex justify-center  p-12">
                    <h1
                        className="text-sm font-semibold"
                        style={{ color: "#aaa" }}
                    >
                        Thank you for the feedback!
                    </h1>
                </div>
            ) : (
                <div className={`flex justify-around items-center  pt-5 ${singleBlock ? 'pb-10' : 'pb-5'} `}>
                    <div
                        className={` flex flex-col items-center cursor-pointer hover:opacity-100 ${productrating != '3' ? "opacity-75" : "opacity-100"} `}
                        onClick={() => {
                            if (OTP) {
                                setproductrating("3");
                                mixpanel.track("clicked product review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '3' })
                            } else {
                                verifyFirst();
                            }
                        }}
                    >
                        <img src={frustated} />
                        <h1 className="font-bold  color-black pt-3 " style={{ fontSize: '10px' }}>
                            Frustated
                        </h1>
                    </div>
                    <div
                        className={` flex flex-col items-center cursor-pointer hover:opacity-100 ${productrating != '5' ? "opacity-75" : "opacity-100"} `}
                        onClick={() => {
                            if (OTP) {
                                setproductrating("5");
                                mixpanel.track("clicked product review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '5' })
                            } else {
                                verifyFirst();
                            }
                        }}
                    >
                        <img src={better} />
                        <h1 className="font-bold  color-black pt-3 " style={{ fontSize: '10px' }}>
                            Can be better
                        </h1>
                    </div>
                    <div
                        className={` flex flex-col items-center cursor-pointer hover:opacity-100 ${productrating != '10' ? "opacity-75" : "opacity-100"} `}
                        onClick={() => {
                            if (OTP) {
                                setproductrating("10");
                                mixpanel.track("clicked product review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '10' })
                            } else {
                                verifyFirst();
                            }
                        }}
                    >
                        <img src={happy} />
                        <h1 className="font-bold  color-black pt-3 " style={{ fontSize: '10px' }}>
                            Extremly Happy
                        </h1>
                    </div>
                </div>
            )}
        </div>)
    }


    const ratings = () => {
        return (
            <div className="bg-white mb-5  px-5 py-5  rounded-md border border-gray-200 shadow-md  ">
                <div>
                    <h1 className="text-sm font-semibold" style={{ color: "#1c1c1c" }} >
                        Based your recent experience with us, how likely are you to
                        recommend us to friends & family?
                    </h1>

                    <div className="flex justify-between pt-3">
                        <div className="flex flex-col items-center  ">
                            <input
                                type="button"
                                onClick={() => {
                                    if (OTP) {
                                        setcourierrating("1");
                                        mixpanel.track("clicked courier review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '1' })
                                    } else {
                                        verifyFirst();
                                    }
                                }}
                                className={`flex justify-center rounded-full h-8 w-8 border-2 border-red-500 hover:bg-red-500 bg-white cursor-pointer ${courierrating == '1' ? "bg-red-500" : ""}`}
                                value="1"

                            />
                            <div className="flex flex-col items-center">
                                <img
                                    src={arrowdown}
                                    className="object-contain py-1.5 "
                                />

                            </div>
                        </div>
                        <input
                            type="button"
                            onClick={() => {
                                if (OTP) {
                                    setcourierrating("2");
                                    mixpanel.track("clicked courier review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '2' })
                                } else {
                                    verifyFirst();
                                }
                            }}
                            className={`flex justify-center rounded-full h-8 w-8 border-2 border-red-400 hover:bg-red-400 bg-white cursor-pointer ${courierrating == '2' ? "bg-red-400" : ""}`}
                            value="2"
                        />
                        <input
                            type="button"
                            onClick={() => {
                                if (OTP) {
                                    setcourierrating("3");
                                    mixpanel.track("clicked courier review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '3' })
                                } else {
                                    verifyFirst();
                                }
                            }}
                            className={`flex justify-center rounded-full h-8 w-8 border-2 border-yellow-400 hover:bg-yellow-400 bg-white cursor-pointer ${courierrating == '3' ? "bg-yellow-500" : ""}`}
                            value="3"
                        />
                        <input
                            type="button"
                            onClick={() => {
                                if (OTP) {
                                    setcourierrating("4");
                                    mixpanel.track("clicked courier review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '4' })
                                } else {
                                    verifyFirst();
                                }
                            }}
                            className={`flex justify-center rounded-full h-8 w-8 border-2 border-green-400 hover:bg-green-400 bg-white cursor-pointer ${courierrating == '4' ? "bg-green-400" : ""}`}
                            value="4"
                        />
                        <div className="flex flex-col items-center">
                            <input
                                type="button"
                                onClick={() => {
                                    if (OTP) {
                                        setcourierrating("5");
                                        mixpanel.track("clicked courier review", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}`, "rating": '5' })
                                    } else {
                                        verifyFirst();
                                    }
                                }}
                                className={`flex justify-center rounded-full h-8 w-8 border-2 border-green-500 hover:bg-green-500 bg-white cursor-pointer ${courierrating == '5' ? "bg-green-500" : ""}`}
                                value="5"
                            />
                            <div className="flex flex-col items-center">
                                <img
                                    src={arrowdown}
                                    className="object-contain py-1.5 "
                                />


                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between justify-center'>
                        <p class="text-xs font-semibold ">Not likely</p>
                        <p class="text-xs font-semibold ">Extremely likely</p>
                    </div>

                    <div className="pt-5  ">
                        {OTP && review?.status === "success" ? (
                            <div className="p-2">
                                <h1
                                    className="text-sm font-semibold"
                                    style={{ color: "#aaa" }}
                                >
                                    Thank you for the feedback!
                                </h1>
                            </div>
                        ) : (
                            <div className="flex items-center">
                                <div className=" w-full ">
                                    <input
                                        type="text"
                                        onChange={handleChange("comment")}
                                        className="rounded-md px-3 py-2 w-full outline-none"
                                        style={{ backgroundColor: "#f2f2f2" }}
                                        placeholder="Enter your remarks"
                                    />
                                </div>
                                <div className=" ml-2 ">

                                    <button
                                        type="submit"
                                        className="px-5 py-2.5 rounded-md text-white font-semibold text-sm"
                                        style={{ backgroundColor: "#3077bf" }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>)
    }


    return (
        <form onSubmit={handleSubmit}>
            <div className={`w-full mx-auto ${singleBlock && 'lg:grid lg:grid-cols-2 lg:gap-2'}`}>
                {reactions()}
                {ratings()}
            </div>

        </form>
    )
}

export default RatingReactionForm