import React, { createRef, useEffect, useLayoutEffect, useState } from 'react'
import './Header.css';
import { SocialIcon } from 'react-social-icons';
import { FiMenu } from "react-icons/fi"
import { GrClose } from "react-icons/gr"
import { FaSortDown } from 'react-icons/fa'
import { useIsOverflow } from '../CustomHooks/useIsOverFlow';

const Header = ({ Details }) => {

    const [Dropdown, setDropdown] = useState()
    const ref = React.useRef();

    const fullSizeMenuItems = () => {
        return <>
            <div style={{ fontSize: '15px' }} className="shrink-0 flex justify-center items-baseline space-x-1 menu-items ">
                {
                    Details?.menu_items.map(item => (
                        item.children && item.children.length ?
                            (
                                <div key={item.name}>
                                    <div className="dropdown " >
                                        <a className=" menu-item cursor-pointer text-gray-500 cursor-pointer  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium" target="_blank" href={item.link}>{item.name} <FaSortDown style={{
                                            display: "inline", verticalAlign: "baseline"
                                        }} /> </a>
                                        <div className="dropdown-content ">
                                            {
                                                item.children.map(subItem => (<a className='hover:underline' href={subItem.link} target="_blank" key={subItem.name}>{subItem.name}</a>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) :
                            <a key={item} href={item.link} target="_blank" className="menu-item hover:underline text-gray-500 cursor-pointer  hover:text-gray-800 dark:hover:text-white px-2 py-2 rounded-md text-md font-medium" >
                                {item.name}
                            </a>
                    ))
                }
            </div>
        </>
    }

    const companyLogo = () => (
        <div className="flex" >
            <a href={Details?.store_url} target='_blank'>
                <img className="max-w-none h-11 cursor-pointer " src={Details?.logo} style={{ backgroundColor: `${Details?.background_color}` }} />
            </a>
        </div>

    )

    const hamburgerIcons = () => (
        <button class="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
            {
                Dropdown ?
                    <GrClose size="35" style={{ padding: "5px" }} onClick={() => { setDropdown(false) }} />
                    :
                    <FiMenu size='35' onClick={() => { setDropdown(true) }} />
            }
        </button>
    )

    const fullSizeSocialIcons = () => (
        <div className="flex items-center md:ml-2">
            {Details?.social?.map((i) => (

                <div className="py-4 pl-5 flex items-center" key={i}>
                    {

                        <SocialIcon url={i.url} bgColor='black' target="_blank" style={{ height: 25, width: 25 }} />
                    }

                </div>
            ))}
        </div>
    )

    const compressedMenuItems = () => {
        return <>
            <div className="absolute right-0 z-10 top-16 bottom-0  h-fit bg-white  px-2 py-2 space-y-1 sm:px-3">


                {
                    Details?.menu_items.map((item) => (
                        item.children && item.children.length ? (
                            <div className='dropdown ' key={item} >

                                <a className="cursor-pointer text-gray-500 cursor-pointer  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium" href={item.link}>{item.name} <FaSortDown style={{
                                    display: "inline", verticalAlign: "baseline"
                                }} /> </a>
                                <div className="dropdown-content absolute right-0">
                                    {
                                        item.children.map(subItem => (<a key={subItem} href={subItem.link} target="_blank" >{subItem.name}</a>))
                                    }
                                </div>
                            </div>
                        ) :
                            <div className='w-max'>

                                <a key={item.name} href={item.link} className="text-gray-500 cursor-pointer  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium">
                                    {item.name}
                                </a>
                            </div>
                    ))
                }

                {/* Social Icons */}

                <div className="hidden md:block">
                    <div className="flex items-center justify-center flex-wrap mt-5">
                        {Details?.social?.map((i) => (

                            <div className=" flex px-2 items-center" key={i}>
                                {

                                    <SocialIcon url={i.url} bgColor='black' target="_blank" style={{ height: 25, width: 25 }} />
                                }

                            </div>
                        ))}
                    </div>
                </div>



            </div>
        </>
    }

    const [lengthExceeded, setLengthExceeded] = useState(false);

    useEffect(() => {

        if (Details?.menu_items.length > 6) { setLengthExceeded(true); }
    }, [Details])


    return (
        <>
            <div className='bg-white border-b border-gray-200 shadow-md '  >


                {
                    Details !== '' && Details?.menu_items && Details?.menu_items.length ? (
                        <nav className="bg-white dark:bg-gray-800   py-2  " ref={ref}>
                            <div className=" mx-auto px-8">
                                <div className="flex  items-center justify-between max-h-16">

                                    <div className=" flex items-center">
                                        {/* Company Logo */}
                                        {companyLogo()}


                                        {/* Menu Items */}
                                        {
                                            <div className={`hidden   ml-10 ${lengthExceeded ? 'lg:block ' : 'md:block'}`}>
                                                {fullSizeMenuItems()}
                                            </div>
                                        }
                                    </div>

                                    {/* Social Icons */}
                                    {<div className="hidden md:block">
                                        {fullSizeSocialIcons()}
                                    </div>
                                    }

                                    {
                                        <div className={`-mr-2 flex md:hidden`}>
                                            {hamburgerIcons()}
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                Dropdown &&

                                // <div class="md:hidden">
                                compressedMenuItems()
                                // </div>
                            }
                        </nav>
                    ) : (

                        // <div className="flex flex-col items-center  justify-center py-2">

                        // Without Menu Item
                        <div className="flex flex-col items-center md:items-start  justify-center py-3 bg-white max-h-16">
                            <a href={Details?.store_url} target='_blank' className="px-6">

                                <img className=" h-11 cursor-pointer " src={Details?.logo} style={{ backgroundColor: `${Details?.background_color}` }} />
                            </a>

                            <div className=" md:inline-flex hidden ml-auto pr-4 absolute  right-0">
                                {Details?.social?.map((i) => (

                                    <div className="py-4 px-2 flex items-center" key={i.url}>
                                        {
                                            <SocialIcon url={i.url} bgColor='black' target="_blank" style={{ height: 25, width: 25 }} />
                                        }
                                    </div>


                                ))}

                            </div>
                        </div>
                    )
                }
            </div>

            <div className={`hidden z-10 flex justify-center  h-fit bg-white  px-2 py-2 space-y-1 sm:px-3 ${lengthExceeded && 'lg:hidden md:block'}`} >
                {fullSizeMenuItems()}
            </div>

        </>
    )
}

export default Header
