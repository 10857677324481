const initialState = {
   
   type:''
    };

const trackReducer =(state=initialState,action)=>{
    switch(action.type){
        case "TRACK_TYPE":
            return {
                type:action?.payload
            }
    }
}
export default trackReducer