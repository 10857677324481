import { useEffect, useState } from 'react';
import Template from './Pages/Template';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import instance from './services';
import MetaTags from 'react-meta-tags';
import mixpanel from 'mixpanel-browser'
import BaseTemplate from './Pages/BaseTemplate';
import TempHome from './Pages/TempHome';
function App() {


  mixpanel.init('4c204a16b06205d093f144d9d89e246a', { debug: false });
  const [Details, setDetails] = useState()
  const [detailsLoading, setDetailsLoading] = useState(false);
  const customer_details = async () => {
    try {

      const res = await instance.get("/api/customer_details")
      // console.log(res?.data?.meta?.data);
      document.title = `Track your ${res?.data?.meta?.data?.brandname ? res?.data?.meta?.data?.brandname : ''} order`;
      setDetails(res?.data?.meta?.data)

      // setDetailsLoading(false);
      await setTimeout(() => {


        setDetailsLoading(false);
      }, 1000);
    }
    catch (e) {
      console.log(e)
      setDetailsLoading(false);
    }
  }

  useEffect(() => {
    setDetailsLoading(true);
    customer_details()
  }, [])



  return (
    <>

      <MetaTags>
        <meta name="description" content={`Track your ${Details?.brandname ? Details?.brandname : ''} order`} />
      </MetaTags>

      <BrowserRouter>


        <Routes>
          <Route path="/" exact element={<TempHome Details={Details} />} />
          <Route path="/track" exact element={<Template Details={Details} detailsLoading={detailsLoading} />} />
          <Route path="/track/:id" exact element={<BaseTemplate Details={Details} detailsLoading={detailsLoading} templateId={Details?.template_id} />} />
          <Route path="/track/order_id/:id" element={<BaseTemplate Details={Details} detailsLoading={detailsLoading} templateId={Details?.template_id} />} />
          <Route path="/track/awb/:id" element={<BaseTemplate Details={Details} detailsLoading={detailsLoading} templateId={Details?.template_id} />} />

        </Routes>

      </BrowserRouter>


    </>
  );
}

export default App;
