import Color from 'color';
import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

const ContactDetailsHeader = ({ Details }) => {

    var themeColor = Details?.theme_color && Details?.theme_color[0] == '#' && Details?.theme_color != "#FFFFFF" && Color(Details?.theme_color).luminosity() < 0.7 ? Details?.theme_color : '#3077bf'; // #10b981

    var textColorBasedOnThemeColor = Color(themeColor).isDark() ? '#FFFFFF' : '#000000';


    return (
        //TODO: it returns always true to work it properly remove the exclamation
        (Details?.email?.value || !Details?.tel?.value) ?
            < div className="flex flex-row text-sm flex-wrap items-center justify-between md:px-10 px-5 py-1" style={{ backgroundColor: themeColor, position: 'relative', bottom: 0, left: 0, right: 0, color: textColorBasedOnThemeColor }
            }>
                {Details?.tel?.value &&
                    <a
                        href={`tel:${Details?.tel?.value}`}
                        target="_blank"
                        className=" flex flex-row items-center "
                    >
                        <FaPhoneAlt color={textColorBasedOnThemeColor} size="0.85rem" style={{ marginRight: 10 }} /> <>{Details?.tel?.value}</>

                    </a>
                }
                {
                    Details?.email?.value &&
                    <a
                        href={`mailto:${Details?.email?.value}`}
                        target="_blank"
                        className=" flex flex-row items-center "
                    >
                        <MdEmail color={textColorBasedOnThemeColor} size="1rem" style={{ marginRight: 10 }} /> <>{Details?.email?.value}</>

                    </a>
                }


            </div > : <></>

    )
}

export default ContactDetailsHeader
