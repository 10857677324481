import React, { useState } from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ImCopy } from "react-icons/im"

function Share(props) {
    const [disp, setdisp] = useState(false)
    const [copyurl, setcopyurl] = useState('')
    const [alert, setAlert] = useState(false);

    const showAlert = () => {

        setAlert(true);

        setTimeout(() => {
            setAlert(false);
        }, 1500);
    }

    const alertBox = () => {
        return (
            <div className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3  fixed top-0 right-0 mx-5 my-5 z-10 w-500" role="alert">
                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" /></svg>
                <p>Link copied!!</p>
            </div>
        )
    }

    return (
        <div>
            {props.showShareModal && <>
                <div className="fixed z-10 top-0 right-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-20">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="flex justify-center pb-5 ">
                                    <span className="cursor-pointer" onClick={() => props.handleClose()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="18px" height="18px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" /></svg>
                                    </span>
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                    <CopyToClipboard text={window.location.href} onCopy={() => { showAlert(); }}>
                                        <div className="  " >

                                            <ImCopy className="cursor-pointer" size="1.5rem" title='Copy URL' />
                                        </div>
                                    </CopyToClipboard>  <br />
                                    <WhatsappShareButton url={window.location.href} ><WhatsappIcon size={32} round={true} /></WhatsappShareButton> <br />
                                    <FacebookShareButton url={window.location.href} ><FacebookIcon size={32} round={true} /></FacebookShareButton>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {alert && alertBox()}
            </>
            }
        </div>
    )
}

export default Share
