import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
const TempHome = ({ Details }) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (Details != "" || Details != null)
            navigate(`/track`, { state: Details })
    }, [Details])

    return (
        <></>
    )
}

export default TempHome