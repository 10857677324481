import React from 'react'

function TilesComponent({ Details, side = false }) {
    return (
        <>
            {
                Details?.image_tiles?.length != 0 &&

                <div className=" md:w-11/12 lg:w-full w-full mx-auto mb-5  h-max  ">



                    {/* <div className="flex md:flex-row flex-col flex-wrap justify-center"> */}
                    <div className={`grid ${side == false ? "lg:grid-cols-4" : ""} gap-2  grid-cols-2`}>

                        {
                            Details?.image_tiles?.map((i) => (
                                <a href={i.url}>
                                    <img className=" h-full w-full" src={i.image} alt="image" />
                                </a>
                            ))
                        }


                    </div>
                </div>
            }</>
    )
}

export default TilesComponent