import React, { useState } from 'react'
import '../App.css'
import Header from '../components/Header'
import TrackingInputCard from '../components/TrackingInputCard'
import Footer from '../components/Footer'
import PreLoader from '../components/PreLoader'
import ContactDetailsHeader from '../components/ContactDetailsHeader'

function Template({ Details, detailsLoading }) {

    const [loadingForTemplate, setLoadingForTemplate] = useState(false)
    return (

        < div className="bg-gray-100" style={{
            fontFamily: `${Details?.font?.name ? Details?.font?.name : "system-ui"}`, height: "100%"
        }}>
            {(detailsLoading || loadingForTemplate) && <PreLoader />}
            {/* header */}
            < div className="border-b-2 bg-white" >
                {/* This is contact details header */}
                <ContactDetailsHeader Details={Details} />
                <Header Details={Details} />

            </div >

            <div className="h-fit bg-gray-100">

                <div className="tracking-wide flex justify-center  px-3.5 md:px-0 pt-16 pb-16">
                    <TrackingInputCard Details={Details} setLoadingForTemplate={setLoadingForTemplate} />
                </div>



                {/* footer */}
                <Footer Details={Details} />

            </div>


        </div >



    )
}

export default Template