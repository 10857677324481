import axios from "axios";
import "./index.css";

const instance = axios.create({
  baseURL: window.location.origin,
  // baseURL: "http://127.0.0.1:8080",
});
instance.interceptors.request.use(
  async (config) => {

    //TODO: Uncomment this before pushing
    if (!window.__user) {
      const baseURL = window.location.origin;
      await axios.get(baseURL + '/api/gettoken'

      ).then((resp) => {
        window.__user = resp?.data;

      }

      ).catch(err => {
        // console.log("err" + err);
      })
    }
    ///TODO: todo end



    config.headers["x-api-token"] = window.__user?.data_app_token; //walktrendy 19584bf5-4ebc-4f7e-91ad-7786c22b3523   //popitout 41f31fa5-6468-4433-a74a-e7d4a8eae758
    // config.headers["x-api-token"] = "dbe69a03-23aa-4a94-93c5-4011fc23f932";  //oswal
    // config.headers["x-api-token"] = "41f31fa5-6468-4433-a74a-e7d4a8eae758";  //popit
    // config.headers["x-api-token"] = "19584bf5-4ebc-4f7e-91ad-7786c22b3523"; //walk
    // config.headers["x-api-token"] = "e9ee382f-f908-4085-8e48-21872e95f45f"; //myra
    // config.headers["x-api-token"] = "a8d7dbd5-063c-4ea0-9e49-7cb081253f30"; //kotonculture
    // config.headers["x-api-token"] = "220c7bd5-9c74-40ce-9d21-fb56c528572f"; //bolt
    config.headers["type"] = localStorage.getItem('type') ? localStorage.getItem('type') : 'awb';    //customer_referenc   //awb
    config.headers["partner-code"] = window.__user?.partner_code;
    // config.headers["partner-code"] = "eshipz";

    return config;
  },
  (error) => {
    // document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);
// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // document.body.classList.remove("loading-indicator");
    return response;
  },
  (error) => {
    console.log(error);
    // document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);
export const get = async (url, params = "") => {
  return await instance.get(`${url}${params}`);
};
export const post = async (url, data = {}) => {
  try {
    return await instance.post(`${url}`, data);
  } catch (e) {
    return e;
  }
};
export default instance;