import React from "react";

import StatusTrack from "../components/StatusTrack";
import CustomerDetails from "../components/CustomerDetails";
import RatingReactionForm from "../components/RatingReactionForm";


function Template1({ Details, status, OTP, id, verifyFirst, setShowNDRModal }) {




    return (


        <div class="flex flex-col lg:flex-row justify-center mx-auto md:w-3/4  xl:pt-4  ">
            {/* 1stdiv */}
            <StatusTrack status={status} Details={Details} />



            {
                // status?.tag === 'Delivered' &&

                <div className="h-max pb-5 mx-auto md:w-full lg:w-9/12 lg:mt-0 mt-5  w-full ">
                    {/* 2nddiv   hidden address for now */}

                    {OTP && <CustomerDetails setShowNDRModal={setShowNDRModal} status={status} />}

                    {/* 3rd div */}

                    <RatingReactionForm status={status} Details={Details} OTP={OTP} verifyFirst={verifyFirst} id={id} singleBlock={false} />
                </div>
            }
        </div>





    );
}

export default Template1;
