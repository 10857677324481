import Color from 'color';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { FaHeadset, FaShareAlt } from 'react-icons/fa';
import Share from './Share';


function StatusTrack({ status, Details }) {

    const [disp, setdisp] = useState(false)
    const [copyurl, setcopyurl] = useState('')
    // const [show, setshow] = useState(false)
    const [showShareModal, SetshowShareModal] = useState(false)
    // const [showOtpModal, setOtpModal] = useState(true); 
    var baseTextColor = '#3077BF';
    // var themeColor = '#9ED2C6';
    var themeColor = Details?.theme_color && Details?.theme_color[0] == '#' && Details?.theme_color != "#FFFFFF" && Color(Details?.theme_color).luminosity() < 0.7 ? Details?.theme_color : '#3077bf'; // #10b981

    var textColorBasedOnThemeColor = Color(themeColor).isDark() ? '#FFFFFF' : '#000000';






    return (

        <div class="h-full mx-auto  bg-white   shadow-md lg:mr-7 rounded-md w-full pb-5 xl:mb-0 ">
            <div className="w-full bg-gray-100 rounded-md p-5 shadow-md  flex md:flex-row divide-x" style={{ borderTop: `solid 10px ${themeColor}` }}>
                {/* <div className="w-full bg-red-500 h-5 absolute top-0 left-0"></div> */}
                <div className="w-5/12 " >

                    {status?.expected_delivery ?
                        <>
                            <div className="text-sm font-semibold  flex ">
                                <div >
                                    {
                                        status?.tag === 'Delivered' ? <p>Order Delivered On </p> : <p>Estimated Delivery Date</p>
                                    }

                                </div>




                            </div>
                            <div className="pt-1">
                                {
                                    status?.tag === 'Delivered' ?
                                        <div className="flex flex-wrap">
                                            <span className="text-sm font-semibold  " style={{ color: baseTextColor }}>
                                                {dayjs(`${status?.checkpoints[0]?.checkpoint_time}`).format("dddd, ")}
                                            </span>
                                            <span className="text-sm font-semibold " style={{ color: baseTextColor }}>
                                                {dayjs(`${status.checkpoints[0]?.checkpoint_time}`).format("MMMM DD")}
                                            </span>
                                        </div>
                                        :
                                        <>


                                            <h1 className="text-sm font-semibold  ">
                                                {dayjs(`${status?.expected_delivery}`).format("dddd,")}
                                            </h1>
                                            <h1 className=" text-sm font-semibold " style={{ color: baseTextColor }}>
                                                {dayjs(`${status.expected_delivery}`).format("MMMM DD")}
                                            </h1>
                                        </>
                                }
                            </div>
                        </> :
                        <>
                            <div className="text font-semibold  flex ">
                                <div >
                                    <p>Order Status:</p>
                                </div>




                            </div>
                        </>
                    }

                    <div className="flex items-center mt-1  ">
                        <h1 className={`font-semibold tracking-wide ${status?.expected_delivery ? "text-sm" : "text-lg"}`} style={{ backgroundColor: themeColor, padding: '2px 5px', color: textColorBasedOnThemeColor }}>
                            {status?.tag?.toUpperCase()}
                        </h1>
                        {/* <h1 className="ml-auto text-xs font-semibold " style={{ color: "#3077bf" }}>Request Cancellation</h1> */}
                    </div>
                </div>
                {/* <div className="border-b-2 mt-5"></div> */}
                <div className="w-7/12">
                    <div className="flex  h-full ">
                        {/* <img src={image4} /> */}
                        <div className="w-4/5 pl-3 h-full ">
                            <div className="ml-2 h-full  flex flex-col justify-between ">
                                <h1 className="text-sm font-semibold">
                                    {status?.slug?.toUpperCase()}
                                </h1>
                                <h1
                                    className="text-sm pt-1"
                                    style={{ color: "#1c1c1c" }}
                                >
                                    <span className="font-semibold">Tracking ID: </span>
                                    <span className="" style={{ color: baseTextColor }} >
                                        {status?.tracking_number}
                                    </span>
                                </h1>
                                <h1
                                    className="text-sm pt-1"
                                    style={{ color: "#1c1c1c" }}
                                >
                                    <span className="font-semibold">Order ID: </span>
                                    <span className="" style={{ color: baseTextColor }}>
                                        {status?.customer_referenc}
                                    </span>
                                </h1>
                            </div>
                        </div>
                        <div className="w-1/5 ">
                            <div className="ml-auto flex flex-col justify-between items-center h-full space-y-1">
                                <a
                                    href={`mailto:${Details?.email?.value}`}
                                    target="_blank"
                                    className=" text-xs  items-center flex flex-col" style={{ color: baseTextColor }}
                                >
                                    <FaHeadset color="#758283" size="1.2rem" />
                                    <span className='text-xs' style={{
                                        fontSize: '10px',
                                        color:
                                            "#758283",
                                        lineHeight: '0.75rem'
                                    }}>Support</span>
                                </a>

                                {/* <div onClick={() => { SetshowShareModal(true); setcopyurl('Copied') }} onMouseOver={() => { setdisp(true) }} onMouseLeave={() => { setdisp(false); setcopyurl('Copy URL') }} className="cursor-pointer" > */}

                                <div onClick={() => { SetshowShareModal(true); }} className="cursor-pointer items-center flex flex-col" >
                                    <FaShareAlt size="1.2rem" color="#758283" />
                                    <span className='text-xs' style={{
                                        fontSize: '10px',
                                        color:
                                            "#758283", lineHeight: '0.75rem'
                                    }}>Share</span>
                                </div>

                            </div>

                        </div>
                        <Share handleClose={() => SetshowShareModal(false)} showShareModal={showShareModal} />


                    </div>
                </div>
            </div>
            <div className="pt-8 px-5 flex flex-col  h-auto max-h-96  overflow-y-scroll overscroll-auto ">
                {status?.checkpoints?.map((i, index) => (
                    <div className="flex justify-center ">
                        <div className="md:w-36 w-20 pr-1 flex-col md:flex-row flex items-start  ">
                            <span className="text-sm font-semibold">
                                {dayjs(`${i.checkpoint_time}`).format("DD MMM")}
                            </span>
                            <span className=" px-1"></span>
                            <span className="text-sm font-medium" style={{ color: baseTextColor }}>
                                {dayjs(`${i.checkpoint_time}`).format("hh:mm A")}
                            </span>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                            <div className="flex flex-col justify-center items-center ">
                                <div className={` flex items-center justify-center rounded-full h-5 w-5 border-2 border-gray-400  `} style={{ borderColor: index === 0 ? themeColor : "" }}>
                                    <div className="bg-gray-400 rounded-full h-3 w-3" style={{ background: index === 0 ? themeColor : "" }}> </div>
                                </div>
                            </div>
                            <div className={index === status?.checkpoints.length - 1 ? "h-full" : `" border-l-2 border ${i.message === "SHIPMENT DELIVERED" ? "" : "border-gray-200"}   h-full "`} ></div>
                        </div>

                        <div className="w-3/4 pl-4 capitalize">
                            <h1 className="font-normal " style={{ fontSize: "12px" }} >
                                <span className="font-semibold text-sm pr-1">Activity:</span>
                                <span className={`text-sm`} style={{
                                    backgroundColor: index === 0 ? themeColor : "",
                                    color: index === 0 ? textColorBasedOnThemeColor : "#6D7073",
                                    fontWeight: index === 0 ? "500" : "normal",
                                    padding: index === 0 ? "1px 5px" : "",
                                    borderRadius: index === 0 ? "3px" : ""
                                }}>{i.message.toLowerCase()}</span>
                            </h1>
                            {/* <h1 className="font-normal " style={{ fontSize: "12px" }} >
                                <span className="font-semibold text-sm pr-1">Activity:</span>
                                <span className={`text-sm`} style={{ color: i.message === "SHIPMENT DELIVERED" ? themeColor : "#6d7073", fontWeight: i.message === "SHIPMENT DELIVERED" ? "500" : "normal" }}>{i.message.toLowerCase()}</span>
                            </h1> */}
                            <h1 className="font-semibold" style={{ fontSize: "12px" }}>
                                <span className="pr-1 text-sm">Location:</span>
                                <span className="font-normal text-sm " style={{ color: "#6d7073" }}>
                                    {i.city?.toLowerCase()}
                                </span>
                            </h1>
                            <div className="my-5"></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StatusTrack