import React from 'react'
import './Tem.css'
import StatusTrack from '../components/StatusTrack'
import CentralImage from '../components/CentralImage'
import CustomerDetails from '../components/CustomerDetails'
import TilesComponent from '../components/TilesComponent'
import RatingReactionForm from '../components/RatingReactionForm'

function Template2({ Details, status, OTP, id, verifyFirst, setShowNDRModal }) {

    return (
        <div className='pt-4 mx-auto md:w-11/12 lg:w-full w-full mx-auto '>
            {OTP &&
                <div className="  mx-auto xl:w-3/4  lg:w-11/12 md:w-3/4   md:p-0 px-5 ">
                    <CustomerDetails setShowNDRModal={setShowNDRModal} status={status} />
                    <RatingReactionForm Details={Details} OTP={OTP} verifyFirst={verifyFirst} id={id} status={status} singleBlock={true} />
                </div>}
            <div className=" flex flex-col lg:flex-row justify-center mx-auto xl:w-3/4  lg:w-11/12 md:w-3/4  md:p-0 px-5">

                <StatusTrack status={status} Details={Details} />

                {Details?.images && Details?.images?.length > 0 ?

                    <CentralImage Details={Details} status={status} />
                    :
                    <TilesComponent side={true} Details={Details} />
                }
            </div>
            {Details?.images && Details?.images?.length > 0 &&
                <div className=" mt-5 mx-auto xl:w-3/4  lg:w-11/12 md:w-3/4   md:p-0 px-5">
                    <TilesComponent Details={Details} />
                </div>
            }



        </div>

    )
}
export default Template2