import React, { useState } from 'react'
import { FaUserShield } from 'react-icons/fa';
import instance from '../services';

const VerifyOtp = ({ handleClose, showTrackModal, verify, id, phone }) => {

    const [number, setNumber] = useState()
    const [opt_error, setotp_error] = useState()


    const generate_otp = async () => {

        try {
            const res = await instance.post("/api/gen_otp", { tracking_number: id, phone: phone })
            // console.log(res)

        }
        catch (error) {
            console.log(error)
        }
    }

    const verify_otp = async () => {
        try {
            const res = await instance.post("api/verify_otp", { otp: number, tracking_number: id })
            // console.log(res.data[0].otp)
            if ((res.data[0].otp === number) && (res.data[0].tracking_number === id)) {
                verify(true)
                handleClose()
            } else {
                setotp_error("Invalid OTP")
            }
        }
        catch (e) {
            console.log(e)
            setotp_error("Invalid OTP")
        }
    }

    return (showTrackModal &&
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="">

                        <div className="flex justify-between items-center px-6 py-3 bg-gray-50 ">
                            <div className='flex items-center '>
                                <div className=" flex items-center justify-center h-8 w-8 rounded-full bg-blue-100 sm:mx-0 sm:h-8 sm:w-8">
                                    {/* <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg> */}
                                    <FaUserShield size="1rem" color="#120E43" />
                                </div>
                                <h3 className="text-lg pl-3 leading-6 font-medium text-gray-900" id="modal-title">
                                    Verify OTP
                                </h3>
                            </div>
                            <span className="cursor-pointer" onClick={() => handleClose()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 50 50" width="18px" height="18px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" /></svg>
                            </span>
                        </div>
                        <div className="sm:flex sm:items-start bg-white px-4  sm:p-6 ">



                            <div className="mt-3 text-center mx-auto sm:mt-0 sm:text-left">

                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        {/* TODO: Check for string if not then convert to string} */}
                                        {`We have sent an OTP on this number xxxxxx${phone.toString()?.substring(phone.toString()?.length - 4)} to verify you as a buyer.`}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <input onChange={(e) => setNumber(e.target.value)} name='otp' type="text" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 sm:text-sm border border-gray-700 rounded-md" placeholder="eg:0000" />
                                </div>
                                <div>
                                    <p className='text-red-600'>{opt_error}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button onClick={verify_otp} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Verify OTP
                        </button>
                        <button type="button" onClick={generate_otp} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            Resend OTP
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyOtp
