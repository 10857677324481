import React from 'react'
import styled from 'styled-components'


export const Input = styled.input
    `
        border:transparent 1px solid;
        &:focus{
            border:${props => props.themeColor} 1px solid;
            outline:none;
      
                }

`
function InputField({ idType, themeColor, handleChange }) {



    return (


        <Input id="inputid" onChange={handleChange("trackingNo")} type='text' className="bg-gray-100 text-sm rounded-md  p-3 w-80" placeholder={idType === 'awb' ? 'Enter Your Tracking Number' : 'Enter Your Order ID'} themeColor={themeColor} />
    );

}

export default InputField