import mixpanel from 'mixpanel-browser'
import React from 'react'

function CentralImage({ Details, status }) {
    return (

        <div className=" md:w-full lg:w-9/12 h-full mx-auto lg:mt-0 mt-5  w-full border ">

            {Details?.images?.map((i) => (
                <>


                    <a href={i.url} target='_blank'  >
                        <img className="w-full h-full transition duration-500 ease-in-out   transform  hover:scale-95  "
                            title={i.text} src={i.image}
                            onClick={() => mixpanel.track("clicked center image", { "customer": `${Details?.brandname}`, "tracking_no": `${status?.tracking_number}` })} />
                    </a>




                </>
            ))}


        </div>
    )
}

export default CentralImage