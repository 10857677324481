
import React, { useState, useEffect } from 'react'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import { useFormValidation } from "../Authenticate/useFormValidation"
import instance from '../services'
import InputField from '../components/InputField'
import { MdErrorOutline } from 'react-icons/md'
import Color from 'color';

function TrackingInputCard({ Details, setLoadingForTemplate }) {


    const [type, settype] = useState(localStorage.getItem('type') ? localStorage.getItem('type') : 'awb')
    const [res, setres] = useState('')
    const navigate = useNavigate()



    var themeColor = Details?.theme_color && Details?.theme_color[0] == '#' && Details?.theme_color != "#FFFFFF" && Color(Details?.theme_color).luminosity() < 0.7 ? Details?.theme_color : '#3077bf'; // #10b981

    var textColorBasedOnThemeColor = Color(themeColor).isDark() ? '#FFFFFF' : '#000000';




    const submit = async () => {
        try {
            setLoadingForTemplate(true);
            const res = await instance.get(`api/getStatus/${data.trackingNo}`)
            // console.log("res", res.data.meta)
            setres(res.data)
            if (!(res.data.msg === 'Order Not found' || res.data.msg === 'Order1 Not found' || res.data.meta.code === 404)) {
                setLoadingForTemplate(false);
                if (type === "awb") {
                    navigate(`/track/awb/${data.trackingNo}`)
                } else if (type === "customer_referenc") {
                    navigate(`/track/order_id/${data.trackingNo}`)
                } else {
                    navigate(`/track/${data.trackingNo}`)
                }

            } else {
                setLoadingForTemplate(false);
            }


        }
        catch (e) {
            setLoadingForTemplate(false);

            console.log(e)
        }
    }


    const { data, errors, handleChange, handleSubmit } = useFormValidation({
        initialValues: {
            trackingNo: '',
        },
        validationSchema: {
            trackingNo: {
                required: {
                    value: true,
                    message: "Please Enter Tracking number",
                },
            },
        },
        submit: submit
    })




    useEffect(() => {
        localStorage.setItem('type', type)
    }, [type])



    const errorBlock = () => {
        return (
            (res?.msg === "Order1 Not found" || res?.msg === "Order Not found" || res?.code === 404) &&
            <div className=" flex items-center border-solid border-1 border-red-600 mx-5 mt-5 p-3  border rounded-md">
                <MdErrorOutline color="#DC2638" size="2rem" />
                <p className='text-red-600 text-sm pl-2'>Tracking / Order Id not registered with us</p>
            </div>
        );
    }



    return (
        <div className="pb-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700" >
            {/* <div className="text-sm font-bold "> */}
            <div>
                <h1 className="drop-shadow-md md:px-5 md:py-2.5 py-2.5 px-10 rounded-t-md font-semibold " style={{ backgroundColor: themeColor, color: textColorBasedOnThemeColor }} >Choose Track Type</h1>
            </div>

            <div className="px-6">
                <form onSubmit={handleSubmit} className=" md:flex md:items-center" >
                    <div>
                        <div className=" flex items-center py-5">
                            <div className="mr-10 flex items-center">
                                <input type='radio' onChange={(event) => settype(event.target.value)} value="awb" name="value" checked={type === 'awb'} style={{ accentColor: themeColor }} id="awbradio" /> <label className="text-sm ml-2" for="awbradio"> Tracking ID/AWB</label>
                            </div>

                            <div className="flex items-center">
                                <input type="radio" onChange={(event) => settype(event.target.value)} value="customer_referenc" name="value" checked={type === 'customer_referenc'} style={{ accentColor: themeColor, outline: "none" }} id="orderidradio" /> <label className='text-sm ml-2' for="orderidradio"> Order ID</label>
                            </div>
                        </div>

                        <div className="flex flex-col items-center">
                            {/* <div className="flex flex-col"> */}
                            <div>

                                <InputField themeColor={themeColor} handleChange={handleChange} idType={type} />




                            </div>
                            {/* <h1 className="text-xs font-bold pt-3 md:hidden text-customblue " >Missed your tracking number?</h1> */}
                            {/* <div className=" pt-5 flex justify-end "> */}
                            <div className=" pt-5 ">
                                <button type="submit" className="md:px-5 md:py-2.5 py-2.5 px-10 rounded-md font-semibold text-xs tracking-wide" style={{ backgroundColor: themeColor, color: textColorBasedOnThemeColor }} >Submit</button>
                            </div>
                        </div>
                    </div>
                </form>


            </div>

            {/* <h1 className="text-xs font-bold pt-3 hidden md:block text-customblue" >Missed your tracking number?</h1> */}
            {errorBlock()}

        </div>
    )
}

export default TrackingInputCard