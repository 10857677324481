import Color from 'color'
import React from 'react'
import { FaHeadset, FaPhoneAlt } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { SocialIcon } from 'react-social-icons'
import eshipz from "../images/eshipz.png"


function Footer({ Details }) {


    return (<>
        <div className="flex items-center justify-center">
            <div>
                <div className="block md:hidden flex ml-auto pl-5 ">
                    {Details?.social?.map((i) => (
                        <div className="py-6 pr-5 flex items-center">
                            {
                                <SocialIcon url={i.url} bgColor='black' style={{ height: 25, width: 25 }} />
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className="ml-auto cursor-pointer fixed bottom-0 right-0">
                <div className="float-right pr-5 pb-3 ">
                    <a href="https://eshipz.com" target="_blank">
                        <h1 className="text-xs font-medium">Powered by</h1>
                        <img src={eshipz} />
                    </a>
                </div>
            </div>


        </div>

    </>
    )
}

export default Footer